* {
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    font-variant: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
