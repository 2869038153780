@import "./src/styles/design-tokens";

.error {
    padding: 25px;
    text-align: center;
    font-family: $typo-font-h2-family;
    font-weight: $typo-font-h2-weight;
    font-size: $typo-font-h2-size;
    line-height: $typo-font-h2-line-height;
}
