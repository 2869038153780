.preloaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;

    & svg {
        display: block;
        width: 30px;
        height: 30px;
    }
}

