@import "./src/styles/design-tokens";

.checkbox {
    width: 100%;
    display: grid;
    grid-template-columns: 25px 1fr;
    padding: 10px;
    column-gap: 18px;
    cursor: pointer;
    box-shadow: 0 4px 30px rgba(177, 192, 235, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
}

.checkbox-hidden-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    & input {
        transform: scale(0);
    }
}

.checkbox-box {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 25px;
    height: 25px;

    box-sizing: border-box;
    border: 1px solid $border-color;
    border-radius: 4px;
    transition: background .5ms ease-in,
    border .5ms ease-in,
    box-shadow .5ms ease-in;

    &.checkbox-checked {
        color: #FFFFFF;
        background: $primary-blue;
    }

    &.checkbox-disabled {
        cursor: default;
        user-select: none;
    }
    &.checkbox-disabled + .checkbox-label {
        cursor: default;
        color: $secondary-color;
    }
}

.checkbox-label {
    font-family: $typo-font-p-family;
    font-weight: $typo-font-p-weight;
    font-size: $typo-font-p-size;
    line-height: $typo-font-p-line-height;
    cursor: pointer;
    user-select: none;
    white-space: pre-wrap;
}

.checkbox-disabled {
    cursor: default;
}
