@import "./src/styles/design-tokens";

.button {
    cursor: pointer;
    width: 100%;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 15px;
    display: block;
    background: $primary-blue;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: background 0.2s ease;

    &:active {
        background: $active-blue;
    }

    &:disabled {
        cursor: default;
        background: $disabled-blue;
    }
}
