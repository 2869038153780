.outer {
    position: relative;
    background-color: #fff;
    min-height: 100vh;
    z-index: 1;
}

.container {
    max-width: 768px;
    margin: 0 auto;
    min-height: 100vh;
    padding: 25px;
    position: relative;
}
