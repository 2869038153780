@import "../../../styles/design-tokens";

.question {
    font-size: $typo-font-p-size;
    font-weight: $typo-font-p-weight;
    font-family: $typo-font-p-family;
    line-height:  $typo-font-p-line-height;
    color: $secondary-color;
    white-space: pre-wrap;
}
