@import "./src/styles/design-tokens";

.question {
    margin-bottom: 85px
}

.name {
    margin-bottom: 25px;
}

.error {
    font-size: $typo-font-p-size;
    font-weight: $typo-font-p-weight;
    font-family: $typo-font-p-family;
    color: $primary-red;
}
.checkboxContainer {
    width: 92%;
    margin-left: auto;
}
