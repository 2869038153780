//colors
$primary-color: #373A42;
$secondary-color: #6D6D6D;
$border-color: #E6E6E6;
$primary-blue: #3051B0;
$active-blue: #2f58c2;
$disabled-blue: #B1C0EB;
$primary-red: #F4533D;

//// h1
$typo-font-h1-family: 'Montserrat', sans-serif;
$typo-font-h1-weight: 700;
$typo-font-h1-size: 36px;
$typo-font-h1-line-height: 150%;

//// h2
$typo-font-h2-family: 'Montserrat', sans-serif;
$typo-font-h2-weight: 700;
$typo-font-h2-size: 24px;
$typo-font-h2-line-height: 180%;

//// p
$typo-font-p-family: 'Montserrat', sans-serif;
$typo-font-p-weight: 500;
$typo-font-p-size: 14px;
$typo-font-p-line-height: 180%;

@mixin flexible {
    display: flex;
    align-items: center;
}

