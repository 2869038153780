@import "./src/styles/design-tokens";
@import "./src/components/Checkbox/Checkbox.module";

.radio {
    @extend .checkbox;
}

.radio-hidden-input {
    @extend .checkbox-hidden-input;
}

.radio-box {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;

    box-sizing: border-box;
    border: 1px solid $border-color;
    transition: background .5ms ease-in,
    border .5ms ease-in,
    box-shadow .5ms ease-in;
    border-radius: 50%;
    cursor: pointer;

    &.radio-checked {
        color: #FFFFFF;

        & .radio-dot {
            background: $primary-blue;
            width: 15px;
            height: 15px;
            border-radius: 50%;
        }
    }

    &.radio-disabled {
        user-select: none;
    }
    &.radio-disabled + .radio-label {
        color: $secondary-color;
    }
}

.radio-readonly {
    cursor: default;
}

.radio-disabled {
    cursor: default;
}

.radio-label {
    @extend .checkbox-label;
}

.radio-label-disabled {
    cursor: default;
}

